.b1{
    background-color: #212121;
}
.b2{
    background-color: #303030;
}
.b3{
    background-color: #000000;
}
.b4{
    background-color: #000000;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,200;8..144,300;8..144,400;8..144,500;8..144,600&display=swap');

body{
    background-color: #000000;
    font-family: 'Roboto Flex', sans-serif;
    font-weight: 400;
    color: white;
    margin: 0px;
    overflow-x: hidden;
}

.shadow{
    box-shadow: 0 2px 10px 1px #000;
}

/* Float */
.hvr-float {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-float:hover, .hvr-float:focus, .hvr-float:active {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  /* Fade */
.hvr-fade {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    overflow: hidden;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
  }
  .hvr-fade:hover, .hvr-fade:focus, .hvr-fade:active {
    background-color: #424242;
    color: white;
  }

  .nolink{
    color: white;
    text-decoration: none;
    margin: 5px;
  }