.profile_width{
    width: 100%;
    display: flex;
    justify-content: center;
}
.profile_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: flex-start; */
    margin: 10px;
    border-radius: 20px;
    max-width: 1180px;
}
.profile_general_top{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 20px;
    padding: 20px;
    border-radius: 20px;
    justify-content: center;
    gap: 20px;
    text-decoration: none;
    color: inherit;
}
.profile_picture{
    border-radius: 10%;
    width: 300px;
    height: 300px;
}
.profile_meta{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
}
.profile_name{
    margin: 0px;
    margin-left: 5px;
}
.profile_github_label{
    margin: 0px;
    width: 100%;
    text-align: center;
}






.profile_entry_buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.profile_entry_title{
    margin: 0px;
}
.profile_entry_button{
    text-decoration: none;
    text-decoration: underline;
    color: inherit;
}

.showcase_image{
    max-width: 600px;
    max-height: 600px;
    width: auto;
}
.showcase_double{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
}
.video_low{
    width: 100%;
}
.showcase_single_image{
    width: 100%;
}



.profile_entry_top{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    align-content: center;
    margin: 20px;
    padding: 30px;
    max-width: 700px;
    text-align: center;
}
.profile_entry_info{
    display: flex;
    flex-direction: column;
    max-width: 500px;
}
.video{
    width: 100%;
    height: auto;
    margin: 20px;
}

p{
    margin: 5px;
}
.youtube{
    background-color: red;
}


.project-text{
    margin: 5px;
    margin-left:40px;
}

.footertext{
    text-align: center;
    margin-bottom: 20px;
}
body{
    background-color: #212121;
}

.icontext{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.slight-grayed-text {
    color: #b0b0b0;
    margin-right: 30px;
}