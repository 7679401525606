.project-top{
    border-radius: 15px;
    margin: 40px;
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.project-top > h1{
    margin: 5px;
}
.project-top > p{
    margin: 5px;
    margin-bottom: 15px;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: inline-block;
    margin: 0px;
}

.wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.buttons{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 5px;
}
